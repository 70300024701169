import React from "react";

import { Excalidraw } from "@excalidraw/excalidraw";


const App = () => {

  return (

    <div style={{ height: "100vh" }}>

      <Excalidraw />

    </div>

  );

};


export default App;
